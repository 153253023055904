import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import styles from './styles.module.css';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import React, { useState } from 'react';

export default function FormPropertiesDialog(props) {
  const { campaign, open, handleClose, updateCampaignHandler } = props;

  const [previewType, setPreviewType] = useState(
    campaign.previewType || 'open'
  );
  const [pageUrls, setPageUrls] = useState(campaign.pageUrls || []);
  const [newPageUrl, setNewPageUrl] = useState('');

  function onPreviewTypeChanged(e) {
    setPreviewType(e.target.value);
  }

  function onNewPageUrlChanged(e) {
    setNewPageUrl(e.target.value);
  }

  function onAddPageUrlButtonPressed(e) {
    const newPageUrls = [...pageUrls, newPageUrl];
    setPageUrls(newPageUrls);
    setNewPageUrl('');
  }

  function onDeletePageUrlButtonPressed(index) {
    const newPageUrls = [...pageUrls];
    newPageUrls.splice(index, 1);
    setPageUrls(newPageUrls);
  }

  function onSaveButtonClicked() {
    const newCampaign = { ...campaign, previewType, pageUrls };
    updateCampaignHandler(newCampaign);
    handleClose();
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      elevation={3}
      className={styles.backdrop}
    >
      <DialogTitle>Form Properties</DialogTitle>
      <DialogContent>
        <div className={styles.subTitle}>
          <span>Preview type</span>
        </div>
        <RadioGroup row value={previewType} onChange={onPreviewTypeChanged}>
          <FormControlLabel
            value="icon"
            control={<Radio color="primary" />}
            label="Icon"
            labelPlacement="end"
          />
          <FormControlLabel
            value="open"
            control={<Radio color="primary" />}
            label="Preview"
            labelPlacement="end"
          />
          <FormControlLabel
            value="autoplay"
            control={<Radio color="primary" />}
            label="Autoplay"
            labelPlacement="end"
          />
        </RadioGroup>
        <div className={styles.subTitle}>
          <span>Form page URLs</span>
        </div>
        <DialogContentText>
          {pageUrls.map((pageUrl, index) => {
            return (
              <Grid className={styles.formUrl} key={index}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  value={pageUrl}
                />
                <DeleteIcon
                  className={styles.actionButton}
                  fontSize="medium"
                  onClick={() => {
                    onDeletePageUrlButtonPressed(index);
                  }}
                />
              </Grid>
            );
          })}
          <Grid className={styles.formUrl}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              focused
              value={newPageUrl}
              onChange={onNewPageUrlChanged}
            />
            <AddBoxIcon
              className={styles.actionButton}
              fontSize="medium"
              onClick={onAddPageUrlButtonPressed}
            />
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          CANCEL
        </Button>
        <Button onClick={onSaveButtonClicked} color="secondary" autoFocus>
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
