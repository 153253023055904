import { db as Firestore } from '../helpers/Firestore';
import { COLLECTIONS } from '../config';

class UserResponseService {
  async getUserResponse(organizationId, formId = undefined) {
    let ref = Firestore.collection(COLLECTIONS.RESPONSE)
      .where('organizationId', '==', organizationId)
      .orderBy('createdAt', 'desc');
    if (formId) {
      ref = ref.where('formId', '==', formId);
    }
    const snapshot = await ref.get();
    return snapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });
  }
  async getUserResponseById(id) {
    const ref = Firestore.collection(COLLECTIONS.RESPONSE).doc(id);
    const snapshot = await ref.get();
    return {
      ...snapshot.data(),
      id: snapshot.id,
    };
  }
}

export default new UserResponseService();
