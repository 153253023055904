import { useState } from 'react';
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Checkbox,
  FormControlLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import styles from './styles.module.css';
import { v4 as uuid } from 'uuid';
import CampaignService from '../../services/CampaignService';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidRoundedIcon from '@mui/icons-material/PhoneAndroidRounded';
import AddIcon from '@mui/icons-material/Add';
export default function AddFormDialog(props) {
  const { formId, openState, closeHandler, updateFormData, formData } = props;
  const [field, setField] = useState();
  const [formFields, setFormFields] = useState();
  // const [targetVideo, setTargetVideo] = useState();
  const [formName, setFormName] = useState();
  const [webhookUrl, setWebhookUrl] = useState();
  const clearState = () => {
    setField({});
    setFormFields([]);
  };
  const closeAddVideoPopup = () => {
    clearState();
    closeHandler();
  };
  const addFieldToForm = () => {
    setFormFields(formFields ? [...formFields, field] : [field]);
    setField({});
  };
  const handleAddForm = async () => {
    const newFormId = uuid();
    // const optionId = uuid();
    const formDetails = {
      name: formName,
      webhookUrl: webhookUrl,
      id: newFormId,
      fields: formFields,
    };
    // const selectedVideoDetails = formData.videos.find((video)=>video.id === targetVideo);
    const updatedFormData = {
      ...formData,
      forms: formData?.forms?.length
        ? [...formData.forms, formDetails]
        : [formDetails],
    };
    await CampaignService.saveCampaign(formId, updatedFormData);
    updateFormData();
    closeAddVideoPopup();
  };
  const handleFieldChange = (event) => {
    setField({
      ...field,
      [event.target.name]: event.target.value,
    });
  };

  const toggleRequiredField = (event) => {
    setField({
      ...field,
      required: event.target.checked,
    });
  };
  // const handleTargetVideoChange = (event) => setTargetVideo(event.target.value);
  const handleFormNameChange = (event) => setFormName(event.target.value);
  const handleWebHookChange = (event) => setWebhookUrl(event.target.value);
  const getIconByType = (type) => {
    switch (type) {
      case 'text':
        return <TextFieldsIcon className={styles.typeIcon} />;
      case 'email':
        return <EmailIcon className={styles.typeIcon} />;
      case 'phone':
        return <PhoneAndroidRoundedIcon className={styles.typeIcon} />;
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={openState}
      onClose={closeAddVideoPopup}
      fullWidth
      maxWidth="xs"
    >
      <div className={styles.dialogContainer}>
        <div className={styles.closeBtn} onClick={closeAddVideoPopup}>
          <CloseSharpIcon fontSize="large" />
        </div>
        <DialogTitle style={{ padding: 0 }}>
          <div className={styles.mainTitle}>Add form</div>
        </DialogTitle>
        <DialogContent>
            <div>
              <TextField
                name="formName"
                type="text"
                label="Form title"
                variant="outlined"
                size="small"
                value={formName || ''}
                onChange={handleFormNameChange}
                autoComplete="current-page"
                fullWidth
              />
            </div>
            <div className={styles.fieldPropsContainer}>
              <TextField
                name="webhookUrl"
                type="text"
                label="Web hookUrl"
                variant="outlined"
                size="small"
                value={webhookUrl || ''}
                onChange={handleWebHookChange}
                autoComplete="current-page"
                fullWidth
              />
            </div>
          <div>
            <p className={styles.addFieldTitle} style={{ marginTop: 25 }}>
              Add form fields
            </p>
            {formFields?.map((field) => (
              <div className={styles.fieldContainer}>
                <div className={styles.title}>{field.name}</div>
                <div className={styles.fieldIcons}>
                  {getIconByType(field.type)}
                </div>
                <div className={styles.fieldIcons}>
                  {field?.required ? (
                    <span>Required</span>
                  ) : (
                    <span>Optional</span>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div>
            <div>
              <TextField
                name="name"
                type="text"
                variant="outlined"
                size="small"
                label="Field name"
                value={field?.name || ''}
                onChange={handleFieldChange}
                autoComplete="current-page"
                fullWidth
              />
            </div>
            <div className={styles.fieldPropsContainer}>
              <FormControl style={{ width: '40%' }}>
                <TextField
                  select
                  name="type"
                  label="Select type"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={field?.type || ''}
                  onChange={handleFieldChange}
                  className={styles.selectInput}
                >
                  <MenuItem value="text">
                    <TextFieldsIcon className={styles.typeIcon} /> Text
                  </MenuItem>
                  <MenuItem value="email">
                    <EmailIcon className={styles.typeIcon} />
                    Email
                  </MenuItem>
                  <MenuItem value="phone">
                    <PhoneAndroidRoundedIcon className={styles.typeIcon} />
                    Phone
                  </MenuItem>
                </TextField>
              </FormControl>
              <span>
                <FormControlLabel
                  checked={!!field?.required}
                  style={{ margin: 0 }}
                  control={<Checkbox />}
                  label="Required"
                  labelPlacement="end"
                  onChange={toggleRequiredField}
                />
              </span>
              <Button
                onClick={addFieldToForm}
                disabled={!field || !field.name || !field.type}
                color="primary"
              >
                <AddIcon
                  className={`${
                    !field || !field.name || !field.type
                      ? styles.disabledAddField
                      : styles.addFieldIcon
                  }`}
                />
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAddForm}
            variant="contained"
            color="primary"
            size="small"
            disabled={!formFields || !formName}
            autoFocus
          >
            Create Form
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
