import React, { useCallback } from 'react';
import { useState } from 'react';
import { auth } from '../../../helpers/Firestore';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import wookeeslogo from './wookeeslogo.png';
import {
  TextField,
  Button,
  Grid,
  Hidden,
  InputAdornment,
  Box,
  Paper,
} from '@material-ui/core';
import Loader from '../../utils/Loader';
import usernameIcon from './person.svg';
import passwordIcon from './lock.svg';
import BannerImage from '../../utils/Images/Banner/index';
import { ROUTES } from '../../../const';

const LoginPageComponent = ({ history }) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [notEmpty, setNotEmpty] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showResponseLoader, setShowResponseLoader] = useState(false);
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      if (!email.value || !password.value) {
        setNotEmpty(true);
        setTimeout(() => {
          setNotEmpty(false);
        }, 2000);
        return;
      }
      try {
        setShowResponseLoader(true);
        await auth.signInWithEmailAndPassword(email.value, password.value);
        history.push('/dashboard');
        setShowResponseLoader(false);
      } catch (error) {
        setIsInvalid(true);
        setShowResponseLoader(false);
      }
    },
    [history]
  );
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);

  return (
    <Grid className={styles.mainDiv}>
      <Box className={styles.outerBoxFrame}>
        <Grid style={{ boxShadow: 3 }} item xs={12} sm={6}>
          <Grid className={styles.formBoxFrame}>
            <h2 className={styles.titleText}>
              <img src={wookeeslogo} alt="" width="200" height="50px" />
            </h2>
            <form onSubmit={handleLogin} className={styles.loginForm}>
              <div className={styles.LoginLabel}>Username or email</div>
              <div className={styles.textCenter}>
                <TextField
                  name="email"
                  size="small"
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Type your username"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="" src={usernameIcon} width="18" height="18" />
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="current-email"
                  color="primary"
                  fullWidth
                />
              </div>
              <div className={styles.LoginLabel}>Password</div>
              <div className={styles.textCenter}>
                <TextField
                  name="password"
                  size="small"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Type your password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="" src={passwordIcon} width="18" height="18" />
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="current-password"
                  color="primary"
                  fullWidth
                />
              </div>
              {isInvalid && (
                <Paper className={styles.errorMsg}>
                  <div>Incorrect username and / or password.</div>
                  <div>
                    Do you need help{' '}
                    <Link to={ROUTES.FORGOT_PASSWORD}>logging in?</Link>
                  </div>
                </Paper>
              )}
              {notEmpty && (
                <Paper className={styles.errorMsg}>
                  <div>Username and / or password should not be empty.</div>
                  <div>Fill out the username and password.</div>
                </Paper>
              )}

              <div className={styles.forgotPasswordWrapper}>
                <Link
                  to={ROUTES.FORGOT_PASSWORD}
                  className={styles.forgotPassword}
                >
                  Forgot password?
                </Link>
              </div>
              <div className={styles.textCenter}>
                <Button
                  className={styles.loginButton}
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth
                  style={{ backgroundColor: '#7449f0' }}
                >
                  Login
                </Button>
              </div>
              <div className={styles.signupHintWrapper}>
                <span>Doesn't have an account? </span>
                <Link to={ROUTES.REGISTER}>Signup</Link>
              </div>
            </form>
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={6}>
            <BannerImage />
          </Grid>
        </Hidden>
      </Box>
      <div style={{ position: 'absolute', top: '50', left: '50' }}>
        {showResponseLoader && <Loader />}
      </div>
    </Grid>
  );
};

export default LoginPageComponent;
