import styles from './styles.module.css';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import companyLogo from './wookeeslogo.png';
import HomeIcon from '@mui/icons-material/Home';
import ForumIcon from '@mui/icons-material/Forum';
import ContactsIcon from '@mui/icons-material/Contacts';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { useHistory } from 'react-router-dom';
import { auth } from '../../../helpers/Firestore';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { ROUTES } from '../../../const';

const TABS = {
  NO_TAB: 0,
  DASHBOARD: 1,
  USER_RESPONSE: 2,
};

const tabStyleActive = {
  backgroundColor: '#fff',
  color: '#7449F0',
};

const tabStyle = {
  color: '#fff',
  backgroundColor: '#7449F0',
};

export default function NavBar() {
  const [selectedTab, setSelectedTab] = useState(TABS.DASHBOARD);

  const logoutBtnPressed = async () => {
    await auth.signOut();
    window.location.reload();
  };
  const history = useHistory();
  const homeOptionSelected = () => {
    history.push(ROUTES.DASHBOARD);
    setSelectedTab(TABS.DASHBOARD);
  };
  const userRespOptionSelected = () => {
    history.push(ROUTES.USER_RESPONSE);
    setSelectedTab(TABS.USER_RESPONSE);
  };
  const contactOptionSelected = () => {
    window.open('https://wookees.com/#regi', '_blank');
  };
  const helpOptionSelected = () => {
    window.open('https://wookees.com/about-us/', '_blank');
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === ROUTES.DASHBOARD || pathname.startsWith('/campaigns/')) {
      setSelectedTab(TABS.DASHBOARD);
    } else if (
      pathname === ROUTES.USER_RESPONSE ||
      pathname.startsWith('/user-responses/')
    ) {
      setSelectedTab(TABS.USER_RESPONSE);
    } else {
      setSelectedTab(TABS.NO_TAB);
    }
  }, []);

  return (
    <Grid className={styles.navBarWrapper}>
      <Grid item xs={12} className={styles.logoDiv}>
        <Link
          to={ROUTES.DASHBOARD}
          style={{ textDecorationColor: 'transparent' }}
        >
          <div style={{ marginTop: '10px' }}>
            <img alt="" src={companyLogo} width="180" height="45" />
          </div>
        </Link>
      </Grid>
      <Grid item xs={12} className={styles.navbar}>
        <div>
          <div
            style={selectedTab === TABS.DASHBOARD ? tabStyleActive : tabStyle}
            onClick={homeOptionSelected}
            className={styles.navBarItem}
          >
            <span>
              <HomeIcon fontSize="small" />
            </span>
            <span className={styles.navItemName}>Home</span>
          </div>
          <div
            style={
              selectedTab === TABS.USER_RESPONSE ? tabStyleActive : tabStyle
            }
            onClick={userRespOptionSelected}
            className={styles.navBarItem}
          >
            <span>
              <ForumIcon fontSize="small" />
            </span>
            <span className={styles.navItemName}>User Response</span>
          </div>
          <div
            style={tabStyle}
            onClick={contactOptionSelected}
            className={styles.navBarItem}
          >
            <span>
              <ContactsIcon fontSize="small" />
            </span>
            <span className={styles.navItemName}>Contact Us</span>
          </div>
          <div
            style={tabStyle}
            onClick={helpOptionSelected}
            className={styles.navBarItem}
          >
            <span>
              <HeadsetMicIcon fontSize="small" />
            </span>
            <span className={styles.navItemName}>Help</span>
          </div>
        </div>
        <div>
          <div
            style={tabStyle}
            onClick={logoutBtnPressed}
            className={styles.navBarItemLogout}
          >
            <span>
              <PowerSettingsNewIcon fontSize="small" />
            </span>
            <span className={styles.navItemName}>Logout</span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
