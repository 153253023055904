import React, { useContext, useState } from 'react';
import TitleBar from '../../utils/TitleBar';
import styles from '../styles.module.css';
import { Grid, TextField, Paper, Button } from '@material-ui/core';
import OrganizationService from '../../../services/OrganizationService';
import UserService from '../../../services/UserService';
import { AuthContext } from '../../../Context/AuthProvider';
import NavBar from '../../utils/NavBar';

export default function CreateOrganizationFormPage() {
  const { user } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState();
  const [data, setData] = useState({});
  const [notEmpty, setNotEmpty] = useState(false);

  const formValueChanged = (e) => {
    const newData = { ...data, [e.target.name]: e.target.value };
    setData(newData);
  };

  const submitBtnPressed = async () => {
    if (
      !data.name ||
      !data.mobile ||
      !data.organizationAddress ||
      !data.organizationName ||
      !data.taxNumber
    ) {
      setNotEmpty(true);
      setTimeout(() => {
        setNotEmpty(false);
      }, 2000);
      return;
    }
    try {
      const orgData = {
        organizationAddress: data.organizationAddress,
        organizationName: data.organizationName,
        taxNumber: data.taxNumber,
      };
      const organizationId = await OrganizationService.createOrganization(
        orgData
      );
      await UserService.createUser(user.id, {
        organizationId,
        name: data.name,
        mobile: data.mobile,
        email: user.email,
      });
      window.location.reload();
    } catch (e) {
      setErrorMessage(e.message);
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
    }
  };
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.contentWrapper}>
        <NavBar />
        <div className={styles.contentAreaWithTitleBar}>
          <TitleBar />
          {errorMessage && (
            <div style={{ color: 'white', backgroundColor: 'red' }}>
              {errorMessage}
            </div>
          )}
          <div className={styles.container}>
            <Paper component="form" className={styles.formPaper}>
              <Grid container>
                <Grid item xs={8} sm={8} md={8} style={{ margin: '25px auto' }}>
                  <h2
                    className={styles.primarySubHead}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    Let's know more about you
                  </h2>
                  <div className={styles.subHeadDiv}>
                    <h4 className={styles.primarySubHead}>
                      Details of your organization
                    </h4>
                  </div>
                  {notEmpty && (
                    <Paper className={styles.errorMsg}>
                      <div>
                        Organization and / or user information should not be
                        empty.
                      </div>
                      <div>Fill out the below form correctly.</div>
                    </Paper>
                  )}
                  <Grid item xs={12} sm={12} className={styles.BillHeader}>
                    <div className={styles.fieldInput}>
                      <TextField
                        name="organizationName"
                        size="small"
                        label="Organization Name"
                        variant="outlined"
                        color="primary"
                        onChange={formValueChanged}
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} className={styles.BillHeader}>
                    <div className={styles.fieldInput}>
                      <TextField
                        name="organizationAddress"
                        label="Organization Address"
                        size="small"
                        variant="outlined"
                        color="primary"
                        onChange={formValueChanged}
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} className={styles.BillHeader}>
                    <div className={styles.fieldInput}>
                      <TextField
                        name="taxNumber"
                        label="Tax Number"
                        size="small"
                        variant="outlined"
                        color="primary"
                        onChange={formValueChanged}
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <div className={styles.subHeadDiv}>
                    <h4 className={styles.primarySubHead}>User Information</h4>
                  </div>

                  <Grid item xs={12} sm={12} className={styles.BillHeader}>
                    <div className={styles.fieldInput}>
                      <TextField
                        name="name"
                        size="small"
                        label="Name"
                        variant="outlined"
                        color="primary"
                        onChange={formValueChanged}
                        fullWidth
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} className={styles.BillHeader}>
                    <div className={styles.fieldInput}>
                      <TextField
                        name="mobile"
                        size="small"
                        label="Mobile"
                        variant="outlined"
                        color="primary"
                        onChange={formValueChanged}
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ textAlign: 'center', marginTop: 40 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ width: 150, backgroundColor: '#2565ad' }}
                      onClick={submitBtnPressed}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}
