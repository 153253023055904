import { useState } from 'react';
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Grid,
} from '@material-ui/core';
import styles from './styles.module.css';
import { v4 as uuid } from 'uuid';
import CampaignService from '../../services/CampaignService';
import { uploadVideo } from '../../services/UploadFIle';
import LoadingBar from '../utils/Loading';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

export default function AddVideoPopup(props) {
  const { formId, formData, openState, closeHandler, updateFormData } = props;
  const [name, setName] = useState();
  const [videoUploading, setVideoUploading] = useState(false);
  const [videoUrl, setVideoUrl] = useState();
  const clearState = () => {
    setName('');
    setVideoUploading(false);
    setVideoUrl('');
  };
  const closeAddVideoPopup = () => {
    clearState();
    closeHandler();
  };
  const handleAddVideo = async () => {
    const video = {
      id: uuid(),
      name: name,
      url: videoUrl,
    };
    const data = {
      ...formData,
      videos: formData.videos ? [...formData.videos, video] : [video],
    };
    await CampaignService.saveCampaign(formId, data);
    updateFormData();
    closeAddVideoPopup();
  };
  const handleVideoUpload = async (e) => {
    setVideoUploading(true);
    const videoLink = await uploadVideo(e.target.files[0]);
    setVideoUrl(videoLink);
    setVideoUploading(false);
  };
  // const handleImageUpload = async (e) => {
  //   setImageUploading(true);
  //   const imageLink = await uploadImage(e.target.files[0]);
  //   setImageUrl(imageLink);
  //   setImageUploading(false);
  // };
  const handleNameChange = (e) => setName(e.target.value);
  return (
    <Dialog
      open={openState}
      onClose={closeAddVideoPopup}
      fullWidth
      maxWidth="xs"
    >
      <div className={styles.dialogContainer}>
        <div className={styles.closeBtn} onClick={closeAddVideoPopup}>
          <CloseSharpIcon fontSize="large" />
        </div>
        <DialogTitle style={{ padding: 0 }}>
          <div className={styles.mainTitle}>New video</div>
        </DialogTitle>
        <DialogContent>
          <div>
            {/* <TextField
            name="name"
            size="small"
            label="Name"
            type="text"
            margin="normal"
            value={name}
            onChange={handleNameChange}
            autoComplete="current-page"
            variant="outlined"
            color="primary"
            fullWidth
          /> */}

            <p className={styles.addFieldTitle}>Video Title</p>
            <TextField
              name="name"
              size="small"
              type="text"
              label="Type your video title"
              variant="outlined"
              value={name}
              fullWidth
              onChange={handleNameChange}
              autoComplete="current-page"
            />
          </div>
          {/* <Grid container className={styles.fileContainer}>
          <Grid md={7} className={styles.videoContainer}>
            <LoadingBar openState={videoUploading} />
            {videoUrl && (
              <video width="100%" height="100%" controls>
                <source src={videoUrl} type="video/mp4" />
              </video>
            )}
          </Grid>
          <Grid md={5} className={styles.uploadButton}>
            <Button variant="contained" color="primary" size="small">
              <input
                hidden
                type="file"
                accept="video/*"
                id="videoUpload"
                onChange={handleVideoUpload}
              />
              <label for="videoUpload">Select Video</label>
            </Button>
          </Grid>
        </Grid>
        <Grid container className={styles.fileContainer}>
          <Grid md={7} className={styles.imageContainer}>
            <LoadingBar openState={imageUploading} />

            {imageUrl && (
              <img width="100%" height="100%" src={imageUrl} alt="" />
            )}
          </Grid>
          <Grid md={5} className={styles.uploadButton}>
            <Button variant="contained" color="primary" size="small">
              <input
                hidden
                type="file"
                accept="image/*"
                id="imageUpload"
                onChange={handleImageUpload}
              />
              <label for="imageUpload">Select image</label>
            </Button>
          </Grid>
        </Grid> */}
          <Grid container className={styles.uploadsContainer}>
            <div className={styles.uploadContainer}>
              <div className={styles.uploadTitle}>Upload Video</div>
              <div className={styles.uploadSection}>
                <LoadingBar openState={videoUploading} />
                {videoUrl && (
                  <video width="100%" height="100%" controls>
                    <source src={videoUrl} type="video/mp4" />
                  </video>
                )}
              </div>
              <div className={styles.uploadBtn}>
                <input
                  hidden
                  type="file"
                  accept="video/*"
                  id="videoUpload"
                  onChange={handleVideoUpload}
                />
                <label for="videoUpload" className={styles.uploadIcon}>
                  <VideoCallIcon fontSize="large" />
                </label>
              </div>
            </div>
            {/* <div
              className={styles.uploadContainer}
              style={{ margin: '0 40px 0 60px' }}
            >
              <div className={styles.uploadTitle}>Upload Image</div>
              <div className={styles.uploadSection}>
                <LoadingBar openState={imageUploading} />

                {imageUrl && (
                  <img width="100%" height="100%" src={imageUrl} alt="" />
                )}
              </div>
              <div className={styles.uploadBtn}>
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  id="imageUpload"
                  onChange={handleImageUpload}
                />
                <label for="imageUpload" className={styles.uploadIcon}>
                  {' '}
                  <AddPhotoAlternateIcon fontSize="large" />
                </label>
              </div>
            </div> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={closeHandler} color="primary">
          CANCEL
        </Button> */}
          <Button
            onClick={handleAddVideo}
            variant="contained"
            color="primary"
            autoFocus
            size="small"
            disabled={!name || !videoUrl}
          >
            Upload video
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
