import { Grid } from '@material-ui/core';
import styles from './styles.module.css';
import React from 'react';

export default function VideoTile(props) {
  const { video } = props;
  return (
    <Grid item>
      <video
        width="100%"
        height="100%"
        controls
        muted
        style={{ objectFit: 'fill' }}
      >
        <source src={`${video.url}#t=0.5`} type="video/mp4" />
      </video>
      <div className={styles.formName}>{video.name}</div>
    </Grid>
  );
}
