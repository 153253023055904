import { Alert, AlertTitle } from '@material-ui/lab';
export default function Info(props){
    const {title,content} = props;
    return(
    <Alert severity="info" style={{marginTop:"15px"}}>
    <AlertTitle><strong>{title}</strong></AlertTitle>
    {content}
  </Alert>
    )

}
