import './App.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import DashboardPageComponent from './components/pages/Dashboard';
import UserResponsePageComponent from './components/pages/UserResponses';
import NotFoundPage from './components/pages/NotFoundPage';
import LoginPageComponent from './components/pages/Login/LoginPageComponent';
import RegisterPageComponent from './components/pages/RegisterPageComponent';
import CampaignDetailsPage from './components/pages/CampaignDetails/CampaignDetailsPage';
import CreateOrganizationFormPage from './components/pages/CreateOrganizationForm';
import ForgotPasswordPageComponent from './components/pages/ForgotPasswordPageComponent';
import EditVideoFormPageComponent from './components/pages/ForgotPasswordPageComponent';
import { AuthProvider } from './Context/AuthProvider';
import PrivateRoute from './PrivateRoute';
import { ROUTES } from './const';
import NoInternetPageComponent from './components/pages/NoInternet';
import ResponseDetails from './components/pages/UserResponses/ResponseDetails';
import PublicRoute from './PublicRoute';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={ROUTES.NO_INTERNET}
            component={NoInternetPageComponent}
          />
          <PublicRoute
            exact
            path={ROUTES.LOGIN}
            component={LoginPageComponent}
          />
          <PublicRoute
            exact
            path={ROUTES.REGISTER}
            component={RegisterPageComponent}
          />
          <PublicRoute
            exact
            path={ROUTES.FORGOT_PASSWORD}
            component={ForgotPasswordPageComponent}
          />

          <Route exact path="/">
            <Redirect to={ROUTES.DASHBOARD} />
          </Route>
          {/*<SelectedTabProvider>*/}
          <PrivateRoute
            exact
            path={ROUTES.DASHBOARD}
            component={DashboardPageComponent}
          />
          <PrivateRoute
            exact
            path={ROUTES.USER_RESPONSE}
            component={UserResponsePageComponent}
          />
          <PrivateRoute
            exact
            path={ROUTES.USER_RESPONSE_DETAILS}
            component={ResponseDetails}
          />
          <PrivateRoute
            exact
            path={ROUTES.CAMPAIGN_DETAILS}
            component={CampaignDetailsPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.CREATE_ORGANIZATION}
            component={CreateOrganizationFormPage}
          />
          <PrivateRoute path="/edit" component={EditVideoFormPageComponent} />
          {/*</SelectedTabProvider>*/}
          <Route path="/*" component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
