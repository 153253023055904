import { CircularProgress } from '@material-ui/core';

export default function LoadingBar(props) {
  const { openState } = props;

  return openState ? (
    <div
      style={{
        position: 'absolute',
        zIndex: '2',
        top: '40%',
        left: '38%',
      }}
    >
      <CircularProgress color="secondary" />
    </div>
  ) : null;
}
