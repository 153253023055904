import React, { useState, useEffect } from 'react';
import TitleBar from '../../utils/TitleBar';
import {
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import CampaignService from '../../../services/CampaignService';
import EditIcon from '@material-ui/icons/Edit';
import { v4 as uuid } from 'uuid';
import AddVideoDialog from '../../popups/AddVideoDialog';
import AddOptionDialog from '../../popups/AddOptionDialog';
import AddFormDialog from '../../popups/AddFormDialog';
import NavBar from '../../utils/NavBar';
import styles from './styles.module.css';
import ReactFlow, { Background, Controls, MiniMap } from 'react-flow-renderer';
import { getReactFlowElements } from '../../../helpers/ReactFlowUtils';

export default function CampaignDetailsPageComponent(props) {
  const [campaign, setCampaign] = useState({});
  const [flowDiagramElements, setFlowDiagramElements] = useState([]);
  const [titleDisabled, setTitleDisabled] = useState(true);
  const { campaignId } = useParams();
  const [addNewOptionParams, setAddNewOptionParams] = useState();
  const [openAddVideoDialog, setOpenAddVideoDialog] = useState(false);
  const [openAddFormDialog, setOpenAddFormDialog] = useState(false);

  const onTitleChanged = (event) => {
    const newFormData = { ...campaign, name: event.target.value };
    setCampaign(newFormData);
  };

  const onTitleEnterPressed = async (e) => {
    if (e.keyCode === 13) {
      setTitleDisabled(true);
      await CampaignService.saveCampaign(campaignId, campaign);
    }
  };

  const onTitleEditButtonClicked = () => {
    setTitleDisabled(false);
  };
  const closeAddVideoDialog = () => setOpenAddVideoDialog(false);
  const handleVideoOptionDialogClose = () => setAddNewOptionParams(null);
  const handleVideoOptionDialogOpen = () => setAddNewOptionParams({});
  const handleVideoDialogOpen = () => setOpenAddVideoDialog(true);
  const handleOpenAddFormDialog = () => setOpenAddFormDialog(true);
  const handleCloseAddFormDialog = () => setOpenAddFormDialog(false);

  // const onNewVideoClicked = () => {
  //   const videos = formData.videos || [];
  //   videos.push({ name: 'Untitled video' });
  //   const newFormData = { ...formData, videos };
  //   setFormData(newFormData);
  //   saveForm();
  // };

  const fnOpenVideoOptionDialog = (params) => {
    setAddNewOptionParams(params);
  };
  const updateFormData = async () => {
    const campaignData = await CampaignService.getCampaign(campaignId);
    setCampaign(campaignData);
    setFlowDiagramElements(getReactFlowElements(campaignData));
  };
  const updateReactFlowElements = (campaign) => {
    setCampaign(campaign);
    setFlowDiagramElements(getReactFlowElements(campaign));
  };

  useEffect(() => {
    async function loadFormData() {
      const campaign = await CampaignService.getCampaign(campaignId);
      campaign.videos = (campaign.videos || []).map((video) => {
        if (!video.id) {
          video.id = uuid();
        }
        return video;
      });
      setCampaign(campaign);
      setFlowDiagramElements(getReactFlowElements(campaign));
    }

    loadFormData().then();
  }, [campaignId]);

  const onConnect = (params) => {
    const video = (campaign?.videos || []).find((x) => x.id === params.source);
    if (video) {
      fnOpenVideoOptionDialog(params);
    }
  };
  const onElementsRemove = async (elementsToRemove) => {
    let videos = campaign.videos || [];
    let forms = campaign.forms || [];
    elementsToRemove.forEach((element) => {
      switch (element.data.customType) {
        case 'video':
          videos = videos.filter((x) => x.id !== element.id);
          break;
        case 'form':
          forms = forms.filter((x) => x.id !== element.id);
          break;
        case 'option':
          const videoIdx = videos.findIndex((vid) => vid.id === element.source);
          if (videoIdx >= 0) {
            videos[videoIdx].options = videos[videoIdx].options.filter(
              (option) => option.id !== element.id
            );
          }
          break;
        default:
          console.error(`Invalid element type '${element.data.customType}'`);
      }
    });
    campaign.videos = videos;
    campaign.forms = forms;

    await CampaignService.saveCampaign(campaignId, campaign);
    updateReactFlowElements(campaign);
  };
  const onNodeDragStop = async (event, node) => {
    const videoInd = (campaign.videos || []).findIndex(
      (vid) => vid.id === node.id
    );
    if (videoInd >= 0) {
      campaign.videos[videoInd].position = node.position;
    } else {
      const formInd = (campaign.forms || []).findIndex(
        (form) => form.id === node.id
      );
      if (formInd >= 0) {
        campaign.forms[formInd].position = node.position;
      } else {
        return;
      }
    }
    await CampaignService.saveCampaign(campaignId, campaign);
  };
  const onLoad = (reactFlowInstance) => {
    setTimeout(() => {
      reactFlowInstance.fitView();
    }, 500);
  };

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.contentWrapper}>
        <NavBar />
        <div className={styles.contentAreaWithTitleBar}>
          <TitleBar />

          <div
            style={{
              height: 'calc(100% - 65px)',
              backgroundColor: '#f1f3f4',
            }}
          >
            <Grid
              container
              style={{
                width: '100%',
                height: 50,
                padding: '10px',
                justifyContent: 'space-between',
              }}
            >
              <Grid item>
                <TextField
                  size="small"
                  disabled={titleDisabled}
                  onChange={onTitleChanged}
                  onKeyDown={onTitleEnterPressed}
                  value={campaign.name || ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={onTitleEditButtonClicked}>
                          <EditIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleVideoOptionDialogOpen}
                  style={{ marginRight: '10px', backgroundColor: '#7449F0' }}
                >
                  New connection
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleOpenAddFormDialog}
                  style={{ marginRight: '10px', backgroundColor: '#7449F0' }}
                >
                  Add form
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ backgroundColor: '#7449F0' }}
                  onClick={handleVideoDialogOpen}
                >
                  New Video
                </Button>
              </Grid>
            </Grid>
            <div style={{ height: 'calc(100% - 60px)' }}>
              <ReactFlow
                elements={flowDiagramElements}
                minZoom={0.1}
                maxZoom={5}
                onConnect={onConnect}
                onElementsRemove={onElementsRemove}
                onNodeDragStop={onNodeDragStop}
                onLoad={onLoad}
              >
                <MiniMap />
                <Controls />
                <Background />
              </ReactFlow>
            </div>
          </div>

          {/* Add option video dialog */}
          <AddVideoDialog
            formId={campaignId}
            openState={openAddVideoDialog}
            closeHandler={closeAddVideoDialog}
            updateFormData={updateFormData}
            formData={campaign}
          />

          {/* add option dialog */}
          {addNewOptionParams && (
            <AddOptionDialog
              closeHandler={handleVideoOptionDialogClose}
              campaign={campaign}
              campaignId={campaignId}
              preSelectedSourceVideoId={addNewOptionParams?.source}
              initialTargetId={addNewOptionParams?.target}
              fnUpdateCampaign={updateReactFlowElements}
            />
          )}

          <AddFormDialog
            formId={campaignId}
            openState={openAddFormDialog}
            closeHandler={handleCloseAddFormDialog}
            updateFormData={updateFormData}
            formData={campaign}
          />
        </div>
      </div>
    </div>
  );
}
