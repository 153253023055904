export const STORAGE_KEYS = {};

export const ROUTES = {
  CREATE_ORGANIZATION: '/create-organization',
  DASHBOARD: '/dashboard',
  USER_RESPONSE: '/user-responses',
  USER_RESPONSE_DETAILS: '/user-responses/:id',
  CAMPAIGN_DETAILS: '/campaigns/:campaignId',
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN: '/login',
  REGISTER: '/register',
  NO_INTERNET: '/no-internet',
};
