import React, { useEffect, useState } from 'react';
import { auth } from '../helpers/Firestore';
import UserService from '../services/UserService';
import Loader from '../components/utils/Loader';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [pending, setPending] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        let user;
        try {
          user = await UserService.getUserById(firebaseUser.uid);
        } catch (e) {
          // User coming first time and not having a reference in the firestore
          if (e.message === 'INVALID_USER_ID') {
            user = { id: firebaseUser.uid, email: firebaseUser.email };
          } else {
            // Failed to load user details from the firestore
            user = {
              id: firebaseUser.uid,
              email: firebaseUser.email,
              failedToLoad: true,
            };
          }
        }
        setUser(user);
      }
      setPending(false);
    });
  }, []);

  return pending ? (
    <Loader />
  ) : (
    <AuthContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
