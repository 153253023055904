import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import { AuthContext } from '../../../Context/AuthProvider';
import styles from './styles.module.css';
import PortableWifiOffRoundedIcon from '@mui/icons-material/PortableWifiOffRounded';
import { Grid, Button } from '@material-ui/core';

export function NoInternetPageComponent({ history }) {
  const onRetryClicked = () => {
    window.location.reload();
  };

  const user = useContext(AuthContext).user;
  if (user && !user.failedToLoad) {
    return <Redirect to="/" />;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: '#7449f0' }}
      className={styles.rootView}
    >
      <Grid item>
        <PortableWifiOffRoundedIcon style={{ fontSize: 75, color: '#fff' }} />
      </Grid>
      <Grid item className={styles.oops}>
        WHOOPS!
      </Grid>
      <Grid item className={styles.nointernet}>
        SLOW OR NO INTERNET CONNECTION
      </Grid>
      <Grid item className={styles.description}>
        Check your internet connection and try again.
      </Grid>
      <Grid item className={styles.buttonDiv}>
        <Button
          variant="contained"
          className={styles.tryBtn}
          onClick={onRetryClicked}
          style={{
            borderRadius: 20,
            width: 150,
            color: '#fff',
            fontWeight: 'bold',
          }}
        >
          Try again
        </Button>
      </Grid>
    </Grid>
  );
}
