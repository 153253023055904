import { storage } from '../helpers/Firestore';
import { v4 as uuid } from 'uuid';

export async function uploadImage(image) {
  if (image == null) return;
  const imageUrl = `/images/${uuid()}.jpg`;
  const ref = storage.ref(imageUrl);
  await ref.put(image);
  return await ref.getDownloadURL();
}

export async function uploadVideo(video) {
  if (video == null) return;
  const videoUrl = `/video/${uuid()}`;
  const ref = storage.ref(videoUrl);
  await ref.put(video);
  return await ref.getDownloadURL();
}
