import styles from './styles.module.css';
import React, { useContext } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AuthContext } from '../../../Context/AuthProvider';

export default function TitleBar(props) {
  const { user } = useContext(AuthContext);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={styles.titleBarLayout}
    >
      <Hidden smDown smUp>
        <Grid item className={styles.controlsContainer}>
          <div className={styles.backBtn}>{props.children}</div>
          <div className={styles.searchField}>
            <InputBase
              style={{ paddingLeft: 12, fontSize: 12 }}
              placeholder="Search Now"
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
          </div>
        </Grid>
      </Hidden>
      <Grid item />
      <Grid item className={styles.userName} style={{ textAlign: 'right' }}>
        <span>{user.name}</span>
        <AccountCircleIcon
          fontSize="large"
          style={{ color: '#7449f0', marginLeft: 10 }}
        />
      </Grid>
    </Grid>
  );
}
