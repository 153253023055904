import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './Context/AuthProvider';
import { ROUTES } from './const';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { user } = useContext(AuthContext);

  function getConsole(routeProps) {
    if (user.organizationId && rest.path === ROUTES.CREATE_ORGANIZATION) {
      console.log(
        `Redirecting from create org. to dashboard as organization available already, OrganizationId: ${user.organizationId}`
      );
      return <Redirect to={ROUTES.DASHBOARD} />;
    }
    return user.organizationId || rest.path === ROUTES.CREATE_ORGANIZATION ? (
      <RouteComponent {...routeProps} />
    ) : (
      <Redirect to={ROUTES.CREATE_ORGANIZATION} />
    );
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !user ? (
          <Redirect to={ROUTES.LOGIN} />
        ) : user.failedToLoad ? (
          <Redirect to={ROUTES.NO_INTERNET} />
        ) : (
          getConsole(routeProps)
        )
      }
    />
  );
};

export default PrivateRoute;
