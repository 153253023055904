import { Grid, Menu, MenuItem, Paper } from '@material-ui/core';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import menuIcon from './menu.png';
import { useState } from 'react';
import defaultImage from './banner.jpg';
import FormPropertiesDialog from '../FormPropertiesDialog/FormPropertiesDialog';
import ConfirmDialog from '../../popups/ConfirmDialog';
import { BASE_APP_URL } from '../../../config';

export default function FormTile(props) {
  const {
    campaign,
    onDuplicateFormClicked,
    updateCampaignHandler,
    campaignDeleteHandler,
  } = props;

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [showPropertiesDialog, setShowPropertiesDialog] = useState(false);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);

  const onOpenMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const dialogHandleClick = () => {
    setShowPropertiesDialog(true);
    setMenuAnchor(null);
  };
  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };
  const handleClosePropertiesDialog = () => {
    setShowPropertiesDialog(false);
  };
  const onDeleteButtonPressed = async () => {
    handleCloseMenu();
    await campaignDeleteHandler(campaign.id);
  };
  const handleCampaignDeleteToggle = () => {
    setDeleteConfirmDialog(!deleteConfirmDialog);
  };
  const handleCampaignDeleteDialogClose = () => {
    handleCloseMenu(null);
    setDeleteConfirmDialog(false);
  };
  const onPreviewOptionSelected = () => {
    window.open(`${BASE_APP_URL}/${campaign.id}`);
  };

  const videos = campaign.videos || [];
  const video = videos.length ? videos[0] : {};
  const videoPreviewUrl = video.image || defaultImage;

  return (
    <Grid item xs={6} sm={3} lg={2}>
      <Paper elevation={3} className={styles.formCard}>
        <Link
          to={`/campaigns/${campaign.id}`}
          style={{ textDecorationColor: 'transparent' }}
        >
          <div className={styles.videoDiv}>
            <img style={{ width: '100%' }} src={videoPreviewUrl} alt="" />
          </div>
        </Link>
        <div className={styles.videoNameDiv}>
          <div>{campaign.name}</div>
          <div style={{ cursor: 'pointer' }} onClick={onOpenMenuClick}>
            <img alt="" src={menuIcon} width="20px" height="15px" />
            {/* <MoreVertIcon fontSize="small" /> */}
          </div>
        </div>
        <div>
          <Menu
            id="simple-menu"
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={handleCloseMenu}
            elevation={3}
          >
            <MenuItem
              onClick={() => {
                onDuplicateFormClicked(campaign);
                handleCloseMenu();
              }}
            >
              Duplicate
            </MenuItem>
            <MenuItem onClick={onPreviewOptionSelected}>Preview</MenuItem>
            <MenuItem onClick={dialogHandleClick}>Properties</MenuItem>
            <MenuItem onClick={handleCampaignDeleteToggle}>Delete</MenuItem>
          </Menu>
        </div>
        <ConfirmDialog
          data={deleteConfirmDialog}
          handleClose={handleCampaignDeleteDialogClose}
          handleSuccess={onDeleteButtonPressed}
          alertTitle={'Confirm Deletion'}
          alertContent={
            "Deleted campaign can't be restored, do you want to delete the campaign?"
          }
          successBtnName={'Delete'}
        />
        {showPropertiesDialog && (
          <FormPropertiesDialog
            campaign={campaign}
            open={showPropertiesDialog}
            handleClose={handleClosePropertiesDialog}
            updateCampaignHandler={updateCampaignHandler}
          />
        )}
      </Paper>
    </Grid>
  );
}
