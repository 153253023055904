import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../../utils/NavBar';
import styles from './styles.module.css';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import TitleBar from '../../utils/TitleBar';
import DataTable from '../../utils/DataTable';
import { AuthContext } from '../../../Context/AuthProvider';
import UserResponseService from '../../../services/UserResponseService';
import CampaignService from '../../../services/CampaignService';
import Info from '../../utils/SnackBar/Info';
import { useHistory } from 'react-router';
import Loader from '../../utils/Loader';

export default function UserResponsePageComponent() {
  const { user } = useContext(AuthContext);
  const [response, setResponse] = useState([]);
  const [showResponseLoader, setShowResponseLoader] = useState(false);
  const [formId, setFormId] = useState('all');
  const [campaignsDetails, setCampaignsDetails] = useState([]);

  const history = useHistory();

  useEffect(() => {
    async function loadResponses() {
      setShowResponseLoader(true);
      const response = await UserResponseService.getUserResponse(
        user.organizationId,
        formId === 'all' ? undefined : formId
      );
      setResponse(response);
      setShowResponseLoader(false);
    }

    loadResponses().then();
  }, [user, formId]);

  useEffect(() => {
    async function loadFormData() {
      const campaigns = await CampaignService.getCampaigns(user.organizationId);
      setCampaignsDetails(campaigns);
    }
    loadFormData().then();
  }, [user]);

  const setResponseDetailsPage = (rowData) => {
    history.push(`/user-responses/${rowData.id}`);
  };
  const handleFilterChange = (event) => {
    setFormId(event.target.value);
  };
  const headerName = [
    {
      id: 'campaignName',
      label: 'Campaign Name',
      minWidth: 150,
    },
    {
      id: 'formName',
      label: 'Form Name',
      minWidth: 150,
    },
    {
      id: 'createdDate',
      label: 'Date',
      format: (row) => row['createdAt'].toDate().toLocaleDateString(),
    },
    {
      id: 'createdTime',
      label: 'Time',
      format: (row) => row['createdAt'].toDate().toLocaleTimeString(),
    },
  ];

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.contentWrapper}>
        <NavBar />
        <div className={styles.contentAreaWithTitleBar}>
          <TitleBar />
          <div className={styles.contentArea}>
            {showResponseLoader ? (
              <Loader />
            ) : (
              <Grid className={styles.tableDiv}>
                <Grid
                  item
                  xs={6}
                  sm={12}
                  lg={12}
                  className={styles.pageTitleSection}
                >
                  <div className={styles.tabTitle}>User Responses</div>
                  <TextField
                    select
                    variant="outlined"
                    size="small"
                    value={formId || 'all'}
                    onChange={handleFilterChange}
                    className={styles.selectBox}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {campaignsDetails &&
                      campaignsDetails.flatMap((campaign) =>
                        (campaign.forms || []).map((form) => (
                          <MenuItem key={form.id} value={form.id}>
                            {`${campaign.name} - ${form.name}`}
                          </MenuItem>
                        ))
                      )}
                  </TextField>
                </Grid>
                <Grid item>
                  {response?.length ? (
                    <DataTable
                      columns={headerName}
                      rows={response}
                      rowClickHandler={setResponseDetailsPage}
                    />
                  ) : (
                    <Info
                      title={'No responses to list'}
                      content={
                        'You have no responses to list with the campaign.'
                      }
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
