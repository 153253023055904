import { db as Firestore } from '../helpers/Firestore';
import { COLLECTIONS } from '../config';

class UserService {
  async getUserById(userId) {
    const ref = Firestore.collection(COLLECTIONS.USERS).doc(userId);
    const snapshot = await ref.get();
    const data = snapshot.data();
    if (!data) {
      throw new Error('INVALID_USER_ID');
    }
    return {
      id: snapshot.id,
      ...data,
    };
  }

  // Save back functions
  async createUser(userId, data) {
    const ref = Firestore.collection(COLLECTIONS.USERS).doc(userId);
    await ref.set(data);
  }
}

export default new UserService();
