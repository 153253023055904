import React, { useCallback, useContext, useState } from 'react';
import { Redirect } from 'react-router';
import { auth } from '../../helpers/Firestore';
import { AuthContext } from '../../Context/AuthProvider';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { TextField, Button, Grid, Hidden, Box, Paper } from '@material-ui/core';
import BannerImage from '../utils/Images/Banner';
import { ROUTES } from '../../const';

const RegisterPage = ({ history }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [username, setUsername] = useState();
  const [notEmpty, setNotEmpty] = useState(false);
  const handleRegister = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password, username } = event.target.elements;
      if (!email.value || !password.value || !username.value) {
        setNotEmpty(true);
        setTimeout(() => {
          setNotEmpty(false);
        }, 2000);
        return;
      }
      try {
        await auth.createUserWithEmailAndPassword(email.value, password.value);
        history.push('/');
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );
  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const { currentUser } = useContext(AuthContext);
  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <Grid className={styles.mainDiv}>
      <Box className={styles.outerBoxFrame}>
        <Grid style={{ boxShadow: 3 }} item xs={12} sm={6}>
          <Grid className={styles.formBoxFrame}>
            <h2 className={styles.titleText}>Signup</h2>
            <div>
              <span className={styles.signupQuote}>
                To get started please sign up...
              </span>
            </div>
            <form onSubmit={handleRegister}>
              <div className={styles.textCenter}>
                <TextField
                  name="email"
                  size="small"
                  label="Email"
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                  autoComplete="current-email"
                  fullWidth
                />
              </div>
              <div className={styles.textCenter}>
                <TextField
                  name="username"
                  size="small"
                  label="Username"
                  type="text"
                  value={username}
                  onChange={handleUsernameChange}
                  autoComplete="current-username"
                  fullWidth
                />
              </div>
              <div className={styles.textCenter}>
                <TextField
                  name="password"
                  size="small"
                  label="Password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  autoComplete="current-password"
                  fullWidth
                />
              </div>

              {notEmpty && (
                <Paper className={styles.errorMsg}>
                  <div>Username and / or password should not be empty.</div>
                  <div>Fill out the username and password.</div>
                </Paper>
              )}
              <div className={styles.textCenter}>
                <Button
                  className={styles.loginButton}
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth
                  style={{ backgroundColor: '#7449f0' }}
                >
                  Create My Account
                </Button>
              </div>
              <div className={styles.signupHintWrapper}>
                <span>Already have an account? </span>
                <Link to={ROUTES.LOGIN}> Login</Link>
              </div>
            </form>
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={6}>
            <BannerImage />
          </Grid>
        </Hidden>
      </Box>
    </Grid>
  );
};

export default RegisterPage;
