import {
  Paper,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './styles.module.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useParams } from 'react-router';
import UserResponseService from '../../../services/UserResponseService';
import NavBar from '../../utils/NavBar';
import TitleBar from '../../utils/TitleBar';
import { useState, useEffect } from 'react';
import { ROUTES } from '../../../const';

const DETAILS_FIELDS = [
  {
    id: 'campaignName',
    name: 'Campaign name',
  },
  {
    id: 'formName',
    name: 'Form name',
  },
];

const META_FIELDS = [
  {
    id: 'id',
    name: 'Response ID',
  },
  {
    id: 'campaignId',
    name: 'Campaign ID',
  },
  {
    id: 'formId',
    name: 'Form ID',
  },
  {
    id: 'createdAt',
    name: 'Created at',
    format: (obj) => {
      if (!obj || !obj.createdAt) return '';
      const dateObj = obj.createdAt.toDate();
      const dateStr = dateObj.toLocaleDateString();
      const time = dateObj.toLocaleTimeString();
      return `${dateStr} - ${time}`;
    },
  },
];

export default function ResponseDetails() {
  const { id: responseId } = useParams();
  const history = useHistory();

  const [detailsFields, setDetailsFields] = useState();
  const [metaFields, setMetaFields] = useState();
  const [isMetaExpanded, setIsMetaExpanded] = useState(false);

  const handleMetaFieldsExpand = () =>setIsMetaExpanded(!isMetaExpanded);

  useEffect(() => {
    const getResponseDetails = async () => {
      const res = await UserResponseService.getUserResponseById(responseId);
      let meta = META_FIELDS.map((metaField) => ({
        value: metaField.format ? metaField.format(res) : res[metaField.id],
        name: metaField.name,
      }));
      setMetaFields(meta);

      const fixedDetailedValues = DETAILS_FIELDS.map((valueField) => ({
        value: valueField.format ? valueField.format(res) : res[valueField.id],
        name: valueField.name,
      }));

      // Removing meta fields response
      META_FIELDS.forEach((field) => {
        delete res[field.id];
      });
      DETAILS_FIELDS.forEach((field) => {
        delete res[field.id];
      });
      delete res.organizationId;

      const formResponseValues = Object.keys(res).map((field) => {
        return {
          value: res[field],
          name: field,
        };
      });
      setDetailsFields(fixedDetailedValues.concat(formResponseValues));
    };
    getResponseDetails().then();
  }, [responseId]);

  const closeHandler = () => history.push(ROUTES.USER_RESPONSE);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.contentWrapper}>
        <NavBar />
        <div className={styles.contentAreaWithTitleBar}>
          <TitleBar />
          <div className={styles.contentArea}>
            {' '}
            <div className={styles.backSection}>
              <div className={styles.backIconSection}>
                <ArrowBackIcon
                  className={styles.backIcon}
                  onClick={closeHandler}
                />
              </div>
              <div className={styles.tabTitle}>Form responses</div>
            </div>
            <Paper elevation={3} className={styles.detailsSection}>
              <div className={styles.detailSectionTitle}>Form response</div>
              <Table aria-label="simple table" className={styles.listSection}>
                <TableBody>
                  {detailsFields &&
                    detailsFields.map((field) => (
                      <TableRow divider className={styles.listItem}>
                        <TableCell
                          className={`${styles.responseText} ${styles.question}`}
                        >
                          {field.name}
                        </TableCell>
                        <TableCell
                          className={`${styles.responseText} ${styles.answer}`}
                        >
                          {String(field.value)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
            <Accordion
              expanded={isMetaExpanded}
              onChange={handleMetaFieldsExpand}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className={styles.detailSectionTitle}>Meta Data</div>
              </AccordionSummary>
              <AccordionDetails>
                <Table aria-label="simple table" className={styles.listSection}>
                  <TableBody>
                    {metaFields &&
                      metaFields.map((field) => (
                        <TableRow divider className={styles.listItem}>
                          <TableCell className={`${styles.responseText} ${styles.question}`}>
                            {field.name}
                          </TableCell>
                          <TableCell
                            className={`${styles.responseText} ${styles.answerPart}`}
                          >
                            {field.value}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}
