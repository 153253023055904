import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { auth } from '../../helpers/Firestore';
import { AuthContext } from '../../Context/AuthProvider';
import Alert from '@material-ui/lab/Alert';
import { TextField, Button, Grid, Hidden, Box, Paper } from '@material-ui/core';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import BannerImage from '../utils/Images/Banner';
import { ROUTES } from '../../const';

const LoginPageComponent = ({ history }) => {
  const [message, setMessage] = useState();
  const [email, setEmail] = useState();
  const [notEmpty, setNotEmpty] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleResetPassword = async (event) => {
    event.preventDefault();
    const { email } = event.target.elements;
    if (!email.value) {
      setNotEmpty(true);
      setTimeout(() => {
        setNotEmpty(false);
      }, 2000);
      return;
    }
    try {
      await auth.sendPasswordResetEmail(email.value);
      setMessage('Password Reset send to your mail id');
      setTimeout(function () {
        history.push('/');
      }, 2000);
    } catch (error) {
      setErrorMessage('Failed to Reset Password');
      setTimeout(function () {
        history.push('/');
      }, 2000);
    }
  };
  const handleEmailChange = (e) => setEmail(e.target.value);

  const { currentUser } = AuthContext;
  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <Grid className={styles.mainDiv}>
      <Box container className={styles.outerBoxFrame}>
        <Grid style={{ boxShadow: 3 }} item xs={12} sm={6}>
          <Grid className={styles.formBoxFrame}>
            <h2 className={styles.titleText}>Can't log in?</h2>
            <form onSubmit={handleResetPassword}>
              <div className={styles.textCenter}>
                <TextField
                  name="email"
                  size="small"
                  label="Email"
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                  autoComplete="current-email"
                  color="primary"
                  fullWidth
                />
              </div>
              <div>
                <span className={styles.signupQuote}>
                  We'll send a recovery link to yor email address.
                </span>
              </div>
              <div style={{ height: '40px' }}>
                {message && <Alert severity="success">{message}</Alert>}
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              </div>

              {notEmpty && (
                <Paper className={styles.errorMsg}>
                  <div>Email should not be empty.</div>
                  <div>Fill out the email address of your account.</div>
                </Paper>
              )}
              <div className={styles.textCenter}>
                <Button
                  className={styles.loginButton}
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth
                  style={{ backgroundColor: '#7449f0' }}
                >
                  Send recovery link
                </Button>
              </div>

              <div className={styles.signupHintWrapper}>
                <span>Return to </span>
                <Link to={ROUTES.LOGIN}> Login</Link>
              </div>
            </form>
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={6} className={styles.bannerDiv}>
            <BannerImage />
          </Grid>
        </Hidden>
      </Box>
    </Grid>
  );
};

export default LoginPageComponent;
