import VideoTile from '../components/utils/VideoTile';
import React from 'react';
import ContactFormTile from '../components/utils/ContactFormTile';

export function getReactFlowElements(campaign) {
  const elements = [];

  // Adding videos
  (campaign?.videos || []).forEach((video) => {
    elements.push({
      id: video.id,
      data: {
        customType: 'video',
        label: <VideoTile video={video} />,
      },
      position: video.position || {
        x: 0,
        y: 0,
      },
      sourcePosition: 'right',
      targetPosition: 'left',
    });

    // Adding video arrows
    (video.options || []).forEach((option) => {
      const target = getTargetId(option);
      if (target) {
        elements.push({
          id: option.id,
          data: {
            customType: 'option',
          },
          source: video.id,
          target: getTargetId(option),
          label: option.title,
          labelBgPadding: [8, 4],
          labelBgBorderRadius: 4,
          labelBgStyle: { fill: '#FFCC00', color: '#fff', fillOpacity: 0.7 },
          arrowHeadType: 'arrowclosed',
        });
      }

      if (option.targetUrl) {
        const pos = {
          x: (video.position?.x || 0) + 250,
          y: video.position?.y || 0,
        };
        elements.push({
          id: option.targetUrl,
          data: {
            label: (
              <a href={option.targetUrl} target="_blank" rel="noreferrer">
                {option.targetUrl}
              </a>
            ),
          },
          position: pos,
          targetPosition: 'left',
          sourcePosition: 'right',
          connectable: false,
        });
      }
    });
  });

  // Adding forms
  (campaign?.forms || []).forEach((form) => {
    elements.push({
      id: form.id,
      data: {
        customType: 'form',
        label: <ContactFormTile form={form} />,
      },
      position: form.position || {
        x: 50,
        y: 50,
      },
      targetPosition: 'left',
      sourcePosition: 'right',
    });
  });

  return elements;
}

function getTargetId(option) {
  return option.targetVideo || option.targetForm || option.targetUrl;
}
