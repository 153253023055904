import { Grid } from '@material-ui/core';
import styles from './styles.module.css';
import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidRoundedIcon from '@mui/icons-material/PhoneAndroidRounded';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { v4 as uuid } from 'uuid';

export default function ContactFormTile(props) {
  const { form } = props;
  const getIconByType = (type) => {
    switch (type) {
      case 'text':
        return <TextFieldsIcon className={styles.icon} />;
      case 'email':
        return <EmailIcon className={styles.icon} />;
      case 'phone':
        return <PhoneAndroidRoundedIcon className={styles.icon} />;
      default:
        return null;
    }
  };

  return (
    <Grid item>
      {form.fields?.map((field) => (
        <div key={field.id || uuid()} className={styles.fieldContainer}>
          <div className={styles.fieldName}>
            {field.name}
            {field.required && <span className={styles.requiredIcon}>*</span>}
          </div>
          <div className={styles.fieldPropsContainer}>
            <div className={styles.fieldPropsIcon}>
              {getIconByType(field.type)}
            </div>
          </div>
        </div>
      ))}
      <div className={styles.formTileName}>{form.name}</div>
    </Grid>
  );
}
