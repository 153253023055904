import { Link } from 'react-router-dom';

export default function NotFoundPage(props) {
  return (
    <div>
      This page is not available now. <Link to="/">Click here</Link> to go to
      home.
    </div>
  );
}
