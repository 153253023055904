import { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Dialog,
} from '@material-ui/core';
import CampaignService from '../../services/CampaignService';
import { v4 as uuid } from 'uuid';

export default function AddOptionDialog(props) {
  const {
    closeHandler,
    campaign,
    campaignId,
    preSelectedSourceVideoId,
    initialTargetId,
    fnUpdateCampaign,
  } = props;

  const [title, setTitle] = useState();
  const [localSelectedSourceVideoId, setLocalSelectedSourceVideoId] =
    useState(null);
  const [targetVideo, setTargetVideo] = useState('');
  const [targetForm, setTargetForm] = useState('');
  const [targetUrl, setTargetUrl] = useState('');

  const saveForm = async () => {
    closeHandler();

    const sourceVideoIdx = getSelectedSourceVideo(
      campaign,
      localSelectedSourceVideoId,
      preSelectedSourceVideoId
    );
    const sourceVideoLoc = campaign.videos[sourceVideoIdx];
    const options = sourceVideoLoc.options || [];
    const newOption = {
      id: uuid(),
      title: title,
    };
    if (targetVideo) {
      newOption.targetVideo = targetVideo;
    } else if (targetForm) {
      newOption.targetForm = targetForm;
    } else if (targetUrl) {
      newOption.targetUrl = targetUrl;
    }
    campaign.videos[sourceVideoIdx].options = [...options, newOption];
    await CampaignService.saveCampaign(campaignId, campaign);
    fnUpdateCampaign(campaign);
    closeHandler();
  };

  function getSelectedSourceVideo(
    campaign,
    preSelectedSourceVideoId,
    localSelectedSourceVideoId
  ) {
    const sourceVideoIdx = campaign.videos.findIndex(
      (video) =>
        video.id === preSelectedSourceVideoId ||
        video.id === localSelectedSourceVideoId
    );
    if (sourceVideoIdx < 0) {
      console.error(
        'Invalid source video, localSelectedSourceVideoId: ',
        localSelectedSourceVideoId,
        ', preSelectedSourceVideoId: ',
        preSelectedSourceVideoId
      );
    }
    return sourceVideoIdx;
  }

  const handleSourceVideoChange = (event) => {
    console.log('event.target.value', event.target.value);
    setLocalSelectedSourceVideoId(event.target.value);
  };
  const handleTargetVideoChange = (event) => {
    setTargetVideo(event.target.value);
    setTargetForm('');
    setTargetUrl('');
  };
  const handleTargetFormChange = (event) => {
    setTargetVideo('');
    setTargetForm(event.target.value);
    setTargetUrl('');
  };
  const handleTargetUrlChange = (event) => {
    setTargetVideo('');
    setTargetForm('');
    setTargetUrl(event.target.value);
  };

  useEffect(() => {
    // Checking video
    const videoIdx = campaign.videos.findIndex(
      (video) => video.id === initialTargetId
    );
    if (videoIdx >= 0) {
      setTargetVideo(initialTargetId);
      return;
    }

    // Checking form
    const formIdx = (campaign.forms || []).findIndex(
      (form) => form.id === initialTargetId
    );
    if (formIdx >= 0) {
      setTargetForm(initialTargetId);
      return;
    }

    // Assuming as targetUrl
    setTargetUrl(initialTargetId);
  }, [initialTargetId, campaign.forms, campaign.videos]);

  return (
    <Dialog open={true} onClose={closeHandler} fullWidth maxWidth="xs">
      <DialogTitle id="alert-dialog-title">
        <div>Add new connection</div>
      </DialogTitle>
      <DialogContent>
        <div>
          <TextField
            name="name"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            size="small"
            label="Title"
            type="text"
            margin="normal"
            autoComplete="current-page"
            variant="outlined"
            color="primary"
            fullWidth
            style={{ marginBottom: '25px' }}
          />
          {(localSelectedSourceVideoId || {}).id}
          <TextField
            id="standard-select-currency"
            select
            name="sourceVideo"
            value={localSelectedSourceVideoId || preSelectedSourceVideoId}
            onChange={handleSourceVideoChange}
            size="small"
            label="Source Video"
            style={{ marginBottom: '15px' }}
            variant="outlined"
            color="primary"
            fullWidth
            disabled={preSelectedSourceVideoId}
          >
            <MenuItem key="empty" value={null}>
              ---
            </MenuItem>
            {(campaign?.videos || []).map((video) => (
              <MenuItem key={video.id} value={video.id}>
                {video.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="standard-select-currency"
            select
            name="targetVideo"
            value={targetVideo}
            onChange={handleTargetVideoChange}
            size="small"
            label="Target Video"
            style={{ marginBottom: '15px' }}
            variant="outlined"
            color="primary"
            fullWidth
          >
            <MenuItem key="empty" value={null}>
              ---
            </MenuItem>
            {(campaign?.videos || []).map(
              (video) =>
                (video.id !== preSelectedSourceVideoId ||
                  video.id !== localSelectedSourceVideoId) && (
                  <MenuItem key={video.id} value={video.id}>
                    {video.name}
                  </MenuItem>
                )
            )}
          </TextField>
          <TextField
            id="standard-select-currency"
            select
            name="targetForm"
            value={targetForm}
            onChange={handleTargetFormChange}
            size="small"
            label="Target Form"
            style={{ marginBottom: '15px' }}
            variant="outlined"
            color="primary"
            fullWidth
          >
            <MenuItem key="empty" value={null}>
              ---
            </MenuItem>
            {(campaign?.forms || []).map((form) => (
              <MenuItem key={form.id} value={form.id}>
                {form.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="standard-select-currency"
            name="targetUrl"
            value={targetUrl}
            onChange={handleTargetUrlChange}
            style={{ marginBottom: '15px' }}
            size="small"
            label="Target URL"
            variant="outlined"
            color="primary"
            fullWidth
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler} color="primary">
          CANCEL
        </Button>
        <Button
          onClick={saveForm}
          color="primary"
          disabled={
            !(
              (targetVideo || targetForm || targetUrl) &&
              title &&
              (localSelectedSourceVideoId || preSelectedSourceVideoId)
            )
          }
          autoFocus
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
