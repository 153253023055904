import { db as Firestore } from '../helpers/Firestore';
import { COLLECTIONS } from '../config';

class CampaignService {
  async getCampaigns(organizationId) {
    const ref = Firestore.collection(COLLECTIONS.FORMS).where(
      'organizationId',
      '==',
      organizationId
    );
    const snapshot = await ref.get();
    return snapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });
  }

  async getCampaign(formId) {
    const ref = Firestore.collection(COLLECTIONS.FORMS).doc(formId);
    const doc = await ref.get();
    return {
      ...doc.data(),
      id: doc.id,
    };
  }

  async createCampaign(data) {
    const ref = Firestore.collection(COLLECTIONS.FORMS).doc();
    await ref.set(data);
    return ref.id;
  }

  async saveCampaign(campaignId, data) {
    delete data.id;
    const ref = Firestore.collection(COLLECTIONS.FORMS).doc(campaignId);
    await ref.set(data);
  }

  async deleteCampaign(campaignId) {
    const ref = Firestore.collection(COLLECTIONS.FORMS).doc(campaignId);
    await ref.delete();
  }
}
export default new CampaignService();
