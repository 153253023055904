import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../../utils/NavBar';
import styles from './styles.module.css';
import { Grid, Paper } from '@material-ui/core';
import plus from './plus.png';
import { AuthContext } from '../../../Context/AuthProvider';
import CampaignService from '../../../services/CampaignService';
import { useHistory } from 'react-router-dom';
import FormTile from '../../utils/FormTile/FormTile';
import TitleBar from '../../utils/TitleBar';

export default function DashboardPageComponent() {
  const { user } = useContext(AuthContext);
  const [campaigns, setCampaigns] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function loadForms() {
      const forms = await CampaignService.getCampaigns(user.organizationId);
      setCampaigns(forms);
    }

    loadForms().then();
  }, [user]);

  const newVideoFormPressed = async () => {
    const campaignId = await CampaignService.createCampaign({
      organizationId: user.organizationId,
      name: `Untitled Campaign`,
    });
    history.push(`/campaigns/${campaignId}`);
  };

  const onDuplicateFormClicked = async (formData) => {
    const newFormName =
      formData && formData.name ? `${formData.name} copy` : 'Untitled Campaign';
    const newCampaignData = { ...formData, name: newFormName };
    const newCampaignId = await CampaignService.createCampaign(newCampaignData);
    const newCampaigns = [
      ...campaigns,
      { ...newCampaignData, id: newCampaignId },
    ];
    setCampaigns(newCampaigns);
  };

  const updateCampaignHandler = async (newCampaignData) => {
    const campaignIdx = campaigns.findIndex(
      (campaign) => campaign.id === newCampaignData.id
    );
    campaigns[campaignIdx] = newCampaignData;
    setCampaigns(campaigns);
    await CampaignService.saveCampaign(
      newCampaignData.id,
      newCampaignData
    ).then();
  };

  const campaignDeleteHandler = async (campaignId) => {
    const newCampaigns = campaigns.filter(
      (campaign) => campaign.id !== campaignId
    );
    setCampaigns(newCampaigns);
    await CampaignService.deleteCampaign(campaignId).then();
  };

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.contentWrapper}>
        <NavBar />
        <div className={styles.contentAreaWithTitleBar}>
          <TitleBar />
          <div className={styles.contentArea}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={3} lg={2}>
                <Paper
                  elevation={3}
                  className={styles.formCard}
                  style={{ display: 'flex' }}
                  onClick={newVideoFormPressed}
                >
                  <img alt="" src={plus} width="60" height="60" />
                </Paper>
              </Grid>

              {campaigns.map((campaign) => (
                <FormTile
                  key={campaign.id}
                  campaign={campaign}
                  onDuplicateFormClicked={onDuplicateFormClicked}
                  updateCampaignHandler={updateCampaignHandler}
                  campaignDeleteHandler={campaignDeleteHandler}
                />
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
